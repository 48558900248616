.container {
  max-width: 375px;
  margin: 5px;
  margin-top: 30px;
}
.slidebarContainer {
  display: flex;
  width: 290px;
  padding: 0 15px;
  margin-bottom: 1rem;
}
.slidebar {
  display: flex;
  flex-basis: 100%;
  height: 4px;
  margin: 5px;
  border: 0.5px solid #3776DB;
}
.slidebarActive {
  background: #3776DB;
}
.radioContainerText {
  font-weight: 400;
}
.questionText {
  font-size: 15px !important;
}
.customPopup {
  padding: 0;
  background: none;
}
