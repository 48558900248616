.LoadingCircle {
  height: 48px;
  width: 48px;
  color: #007bff;
  position: relative;
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: LoadingCircleRotate 1.3s linear infinite;
  
  @keyframes LoadingCircleRotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
