.Pricing {
  text-align: center;
  margin-top: 15px;
  
  .package-group {
    border-radius: 5px;
    padding: 0 10px;
  }
  .monthly-packages {
    background-color: rgba(221, 221, 221, 0.52);  
  }
  .group-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 1rem 0;
  }
  .sell-title {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .content-block {
    display: flex;
    flex-direction: column;
    .content {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 480px) {
    .content-block {
      flex-direction: row;
      .content {
        padding-left: 1rem;
        text-align: left;
      }
    }
  }
  
  .summary-box-header {
    font-size: 12px;
    color: #747474;
  }
  .summary-box-item {
    color: #2C3140;
    font-size: 14px;
    font-weight: 400;
  }
  .summary-box-total {
    font-size: 18px;
    color: #2C3140;
    font-weight: bold;
  }

  .payment-box-subtitle {
    color: #747474;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-top: 15px;
  }
  .payment-box-item {
    display: flex;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    max-width: 240px;
    height: 110px;
    margin-top: 15px;
    margin-left: 15px;
    flex-direction: column;
    cursor: pointer;
  }
  .payment-box-item-input {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  .payment-box-item.selected {
    border: 1px solid #28A745;
  }

  .footer-block {
    .logos img {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .bg-dark {
    background: #2C3140;
    color: #FFF;
  }
}
