.Plan {
  padding: 15px 10px;
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px 1px rgba(193, 193, 193, 0.35);
  border-radius: 4px;
  margin: 0;
  margin-bottom: 15px;
  max-width: 350px;
  width: 100%;

  h4 {
    font-size: 20px;
  }

  .object-counter {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .discount-box {
    background: #FF0000;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    padding: 3px 10px;
  }

  .price-block {
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 1rem;
    sup {
      font-size: 24px;
      font-weight: 600;
      vertical-align: super;
      top: -0.1em;
    }
    sub {
      font-size: 16px;
      font-weight: 300;
	    vertical-align: sub;
      bottom: 0.5em;
    }
  }

  .discounted-text {
    color: #747474;
    font-size: 12px;
    margin-bottom: 2.5rem;
  }
  .no-discount-space {
    min-height: 24px;
  }
  .no-discount-dropdown-space {
    min-height: 50px;
  }
  @media screen and (max-width: 767px) {
    .no-discount-space {
      min-height: 15px;
    }
    .no-discount-dropdown-space {
      min-height: 0;
    }
  }

  .total-text {
    color: #747474;
    margin-bottom: 0.5rem;
  }

  .blank-box {
    display: flex;
    height: 50px;
  }

  .active-box {
    background-color: rgba(61, 175, 7, 0.78);
    display: flex;
    height: 50px;
    color: #FFF;
    justify-content: center;
    align-items: center;
  }

  .object-dropdown {
    .dropdown-toggle {
      width: 200px;
      height: 36px;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.96px;
      color: #000000;
      border: 1px solid #DFDFDF;
      border-radius: 7px;
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-toggle-title {
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .dropdown-toggle-arrow {
      background-image: url('data:image/svg+xml,<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.499395 1.15565L6.15625 6.8125L11.8131 1.15565" stroke="%2304042D" stroke-linecap="round"/></svg>');
      background-repeat: no-repeat;
      background-position: right;
      width: 20px;
      height: 8px;
      border: none;
      vertical-align: 0;
      flex: 0 0 auto;
    }
    .dropdown-item {
      cursor: pointer;
    }
  }

  .btn-subscribe {
    min-width: 200px;
    height: 50px;
    border: 2px solid #1465FA;
    color: #1465FA;
    font-size: 12px;
    font-weight: 500;

    &:hover {
      color: #fff;
      background-color: #1762af;
      border-color: #1762af;
    }
  }

  .horizontalLine hr {
    width: 100px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  ul.feature-list {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: left;
    padding-inline-start: 0;

    li {
      list-style: none;
      font-size: 12px;
    }
  }

  .tick {
    margin-right: 2px;

    &.tick-active {
      path {
        stroke: #68C13E;
      }
    }
  }

  .pro-text {
    position: absolute;
    margin-left: 3px;
  }

  &.dark {
    background: #2C3140;
    color: #FFF;

    .discounted-text {
      color: #DDDDDD;
    }
    .btn-subscribe {
      background-color: #1465FA;
      color: #FFF;
      
      &:hover {
        border: 2px solid #1465FA;
        background-color: #FFFFFF;
        color: #1465FA;
      }
    }
    .total-text {
      color: #DDDDDD;
    }
    .horizontalLine hr {
      background-color: #DDDDDD;
    }
  }
}
